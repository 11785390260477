import * as react from '@remix-run/react'

import {
	ErrorComponent,
	GeneralErrorBoundary,
} from '~/components/ErrorBoundary.tsx'

export async function loader() {
	throw new Response('Not found', { status: 404 })
}

export default function NotFound() {
	// due to the loader, this component will never be rendered, but we'll return
	// the error boundary just in case.
	return <ErrorBoundary />
}

export function ErrorBoundary() {
	const location = react.useLocation()
	return (
		<GeneralErrorBoundary
			statusHandlers={{
				404: () => (
					<ErrorComponent
						heading="404 - Page not found"
						error={`Page not found at ${location.pathname}`}
						link={{
							to: '/',
							label: 'Go to home',
						}}
					/>
				),
			}}
		/>
	)
}
