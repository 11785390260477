import {
	isRouteErrorResponse,
	Link,
	useParams,
	useRouteError,
	type ErrorResponse,
} from '@remix-run/react'

import { getErrorMessage } from '~/utils/index.ts'

type StatusHandler = (info: {
	error: ErrorResponse
	params: Record<string, string | undefined>
}) => JSX.Element | null

export function GeneralErrorBoundary({
	defaultStatusHandler = ({ error }) => <ErrorComponent error={error} />,
	statusHandlers,
	unexpectedErrorHandler = error => (
		<ErrorComponent error={getErrorMessage(error)} />
	),
}: {
	defaultStatusHandler?: StatusHandler
	statusHandlers?: Record<number, StatusHandler>
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}) {
	const error = useRouteError()
	const params = useParams()

	if (typeof document !== 'undefined') {
		console.error(error)
	}

	return (
		<div className="grid h-full place-items-center p-20">
			{isRouteErrorResponse(error)
				? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
						error,
						params,
				  })
				: unexpectedErrorHandler(error)}
		</div>
	)
}

export function ErrorComponent({
	heading = 'Something went wrong!',
	error,
	link = {
		to: '.',
		label: 'Try again',
	},
}: {
	heading?: string
	error: ErrorResponse | string
	link?: {
		to: string
		label: string
	}
}) {
	return (
		<div className="grid max-w-xl gap-6">
			<div className="grid gap-3">
				<h1 className="text-heading1">{heading}</h1>
				<pre className="whitespace-pre-wrap break-all text-lg font-semibold">
					{typeof error === 'string'
						? error
						: `${error.status} - ${error.data}`}
				</pre>
			</div>
			<Link to={link.to} className="font-medium">
				{link.label}
			</Link>
		</div>
	)
}
